import React, {useState, useEffect} from 'react'
import blueBlack from '../../assets/images/background/blue-black.jpg'
import { BalanceOf } from '../../modules/prediction/Conditional/balanceOf';
import { ConditionId } from '../../modules/prediction/Conditional/conditionId';
import { CollectionId } from '../../modules/prediction/Conditional/collectionId';
import { PositionId } from '../../modules/prediction/Conditional/positionId';
import { PayoutNumerator } from '../../modules/prediction/Conditional/payoutNumerator';
import { PayoutDenominator } from '../../modules/prediction/Conditional/payoutDenominator';
import { SlotCount } from '../../modules/prediction/Conditional/slotCount';
import { CreateFPMM } from '../../modules/prediction/createFPMM';
import { IsApproved } from '../../modules/prediction/Conditional/isApproved';
import { Allowance } from '../../modules/allowance'
import tokensAddrs from '../../config/contracts/tokens.json'
import { Prepare } from '../../modules/prediction/Conditional/actions/prepare';
import { useError } from '../../components/context/ErrorProvider';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import predictionAddrs from '../../config/contracts/prediction.json'

const PredictionAdminPage = () => {
  const { chainData , setChainData, walletInfo, isConnected } = useGlobalState();
  const [ tokenAddy , setTokenAddy ] = useState()
  const [ balanceOf, setBalanceOf ] = useState()
  const [ conditionId, setConditionId ] = useState()
  const [ conditionalIds, setConditionalIds ] = useState()
  const [ collectionId, setCollectionId ] = useState()
  const [ positionId, setPositionId ] = useState()
  const [ isApproved, setIsApproved ] = useState()
  const [ slotCount, setSlotCount ] = useState()
  const [ nPayouts, setNPayouts] = useState()
  const [ dPayout, setDPayout] = useState()
  const [ questId, setQuestId ] = useState()
  const [ mmFee, setMMFee ] = useState(0)
  const [ oracleAddy, setOracleAddy ] = useState()
  const [ conditionalAddy, setConditionalAddy ] = useState()
  const [ collectId, setCollectId ] = useState()
  const { showPopUp, closePopup } = useError();


  useEffect(() => { //WALLET INFO HERE!
    if (isConnected) {
      var tokenAddress = tokensAddrs.Friendship[42161]
      tokenAddress = "0xc674B7Bed1D086584c8776C1B83013009cC294e7"
      setTokenAddy(tokenAddress)
      const fetchData = async () => {
        try {
            console.log('tokenAddy',tokenAddress)

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      getConditionalInfo()

    }
  }, [isConnected])

  function resultsHandle( myResult ){

    if(myResult[0]){
      showPopUp("Transaction Success: "+ myResult[1], "success")
    } else {
      showPopUp("Error: "+ myResult[1], "error")
    }

  }

  async function getConditionalInfo(){
    //conditionalAddress, ownerAddress, id,condit 0x65e2a1f20b5eaf759a778342d42a8f60d6ab6222887464566b921ba6f5e7549f
    var id = 0
    var ownerAddress = '0x995fa5eadCDf96268C7a99357d87fe89dCF7EDd2' //walletInfo.signer.address
    var oracleAddress = '0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09'
    var questionId = '0x4b22fe478b95fdaa835ddddf631ab29f12900b62061e0c5fd8564ddb7b684333'
    var parentCollectionId = '0x0000000000000000000000000000000000000000000000000000000000000000'
    var indexSet = 0
    var outcomeSlotCount = 2
    //var operatorAddress = predictionAddrs.MarketMaker[42161] collection 0x5d650b5cf596b6520fc045bfe886d6a45e54bc3cae812d92773823c55c834d8f


    var balance = await BalanceOf( predictionAddrs.ConditionalToken[42161], walletInfo.signer.address, id, walletInfo.provider, 9)
    var condition = await ConditionId( predictionAddrs.ConditionalToken[42161],  oracleAddress, questionId, outcomeSlotCount, walletInfo.provider)
    var collection = await CollectionId( predictionAddrs.ConditionalToken[42161], parentCollectionId, condition, indexSet, walletInfo.provider)
    var position = await PositionId( predictionAddrs.ConditionalToken[42161], '0xc674B7Bed1D086584c8776C1B83013009cC294e7', collection, walletInfo.provider)
    var dPayout = await PayoutDenominator( predictionAddrs.ConditionalToken[42161], "0x65e2a1f20b5eaf759a778342d42a8f60d6ab6222887464566b921ba6f5e7549f", walletInfo.provider)
    var nPayout = await PayoutNumerator( predictionAddrs.ConditionalToken[42161], "0x65e2a1f20b5eaf759a778342d42a8f60d6ab6222887464566b921ba6f5e7549f",
     '0x0000000000000000000000000000000000000000000000000000000000000001', walletInfo.provider)
    var slots = await SlotCount( predictionAddrs.ConditionalToken[42161],  condition, walletInfo.provider)
    //var approved = await IsApproved( predictionAddrs.ConditionalToken[42161],  ownerAddress, operatorAddress, walletInfo.provider)

    setDPayout(dPayout)
    setNPayouts(nPayout)
    setBalanceOf( balance )
    setCollectionId( collection )
    setConditionId( condition )
    setPositionId( position )
    //setIsApproved (approved.toString() )
    setSlotCount (slots.toString() )
    console.log('condition',condition, 'collection',collection, 'payout', dPayout, nPayout )

  }
  //async function Preparer= () => {

    //var oracleAddress = '0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09'
    // questionId = '0x4b22fe478b95fdaa835ddddf631ab29f12900b62061e0c5fd8564ddb7b684335'
    //var outcomeSlotCount = 5
  const Preparer = async () => {
    console.log('preparer  setOracleAddy:', oracleAddy, questId, slotCount)
    var result = await Prepare( predictionAddrs.ConditionalToken[42161], oracleAddy, questId, slotCount, walletInfo.provider,  walletInfo.signer )

     resultsHandle(result)
     //once accpeted the result needs to be saved locally some how see marketsConfig.js

  }

  async function Reporter(){
    console.log('reporter')

  }

  async function Redeemer(){
    console.log('redeemer')
  }
  async function Conditioner(){
    console.log('conditioner')
    var condition = await ConditionId( predictionAddrs.ConditionalToken[42161],  oracleAddy, questId, slotCount, walletInfo.provider)
    setConditionId(condition)
    console.log('collector',condition)
  }

  const handleCondtionsChange = (e) => {
      const value = e.target.value;
      // Split the string by commas and trim whitespace from each item
      const arrayFromInput = value.split(',').map(item => item.trim());
      setConditionalIds(arrayFromInput);
    };

  async function CreateNewFPMM(){
    console.log('createFPMM')
    //let condIds = 
    var result = await CreateFPMM( predictionAddrs.FPMarketMakerFactory[42161],  predictionAddrs.ConditionalToken[42161],tokenAddy,
    conditionalIds  ,mmFee  ,walletInfo.provider,  walletInfo.signer)
    //conditionalTokens, collateralToken, conditionIds ,fee,  provider, signer
    resultsHandle(result)
    //resultsHandle(condition)


    /*
    <p>
    questionId:  <input type="text"></input>
    payouts ([a,b]): <input type="text"></input>
<p>collection ID: {collectionId} </p>
    <button className='button' onClick={Reporter}>Report Pay</button>
    <p>conditiion ID: {conditionId} </p>
    <p>slotcount: {slotCount} </p>
    <p>isapproved: {isApproved} </p>
    */
  }



  return (
    <section className="newsletter section">
      <div className="demoDapp-container">
        <div className="centered-div customForm nftPage">
          <h1 className="vhead">SommiFans Prediction AdminPage</h1>
          {console.log(window.ethereum)}





<p>
<p>token addy:{tokenAddy}</p>
<p>balance: {balanceOf}</p>


<p>positionId: {positionId} </p>
          questionId: <input type="text" style={{ width:"100%" }} onChange={(e) => setQuestId(e.target.value)}></input>
          oracleAddress: <input type="text" style={{ width:"100%" }} onChange={(e) => setOracleAddy(e.target.value)} ></input>
          slotCount: <input type="text"  style={{ width:"100%" }} onChange={(e) => setSlotCount(e.target.value)}></input>
          <button className='button' onClick={Preparer}>Prepare</button>
          <button className='button' onClick={Redeemer}>Redeem Position</button>
          <br/><br/><div>ConditionID: {conditionId}</div>
          <div><button className='button' onClick={Conditioner}>Get Condition</button></div>
          <br/><br/><br/><br/>
          ConditionalToken: <input type="text" style={{ width:"100%" }}  onChange={(e) => setTokenAddy(e.target.value)}></input>
          Conditional Ids: <input type="text" style={{ width:"100%" }}  onChange={handleCondtionsChange} placeholder="Enter comma-separated values"></input>
          Fee %(?): <input type="text"  style={{ width:"100%" }} onChange={(e) => setMMFee(e.target.value)}></input>

          <button className='button' onClick={CreateNewFPMM}>Create Market</button>


          </p>
        </div>
        <img src={blueBlack} />
      </div>
    </section>
  );
};

export { PredictionAdminPage };
