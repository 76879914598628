import React, { useState, useEffect } from 'react';
import './input.css';

const PlusMinusInput = ({value, setValue, increment=1}) => {
  const [focused, setFocused] = useState(false);
  const isDarkMode = false 
  
  const grey = {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  };

  const cyan = {
    50: '#e0f7fa',
    100: '#b2ebf2',
    200: '#80deea',
    300: '#4dd0e1',
    400: '#26c6da',
    500: '#00bcd4',
    600: '#00acc1',
    700: '#0097a7',
    800: '#00838f',
    900: '#006064',
  };
  useEffect(() => {
    document.documentElement.style.setProperty('--color', isDarkMode ? grey[300] : grey[900]);
    document.documentElement.style.setProperty('--background', isDarkMode ? grey[900] : '#fff');
    document.documentElement.style.setProperty('--border', isDarkMode ? grey[700] : grey[200]);
    document.documentElement.style.setProperty('--boxShadow', isDarkMode ? grey[900] : grey[50]);
    document.documentElement.style.setProperty('--hoverBorder', cyan[400]);
    document.documentElement.style.setProperty('--focusBorder', cyan[400]);
    document.documentElement.style.setProperty('--focusShadow', isDarkMode ? cyan[600] : cyan[200]);
    document.documentElement.style.setProperty('--btnHoverBackground', isDarkMode ? grey[800] : grey[50]);
    document.documentElement.style.setProperty('--btnHoverBorder', isDarkMode ? grey[600] : grey[300]);
    document.documentElement.style.setProperty('--btnHoverColor', grey[50]);
  }, [isDarkMode, grey, cyan]);

  const handleIncrement = () => {
    setValue(value + increment);
  };

  const handleDecrement = () => {
    if (value > increment) {
      setValue(value - increment);
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue > 0) {
      setValue(newValue);
    }
  };

  return (
    <div 
      className={`CustomNumberInput ${focused ? 'focused' : ''}`} 
      onFocus={() => setFocused(true)} 
      onBlur={() => setFocused(false)}
    >
      <input 
        value={value} 
        onChange={handleChange} 
        min="1" 
        className="input"
      />
      <button className="btn increment" onClick={handleIncrement}>
        <span className="arrow">&uarr;</span>
      </button>
      <button className="btn decrement" onClick={handleDecrement}>
        <span className="arrow">&darr;</span>
      </button>
    </div>
  );
};

export {PlusMinusInput};
