import React, { useState } from 'react';
import PropTypes from 'prop-types'
import './alert.css';

const PopUpMsg = ({ msg, onClose, errorType }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose();
  };
  


  return (
    <div className='alert-container'>
        <div className={!errorType ? "custom-alert" : errorType+"-alert custom-alert"}>
            <svg
                className="flex-shrink-0 me-2"
                width="30"
                height="30"
                viewBox="0 0 512 512"
            >
                <rect
                width="30"
                height="176"
                x="240"
                y="176"
                fill="currentColor"
                ></rect>
                <rect
                width="35"
                height="35"
                x="240"
                y="384"
                fill="currentColor"
                ></rect>
                <path
                fill="currentColor"
                d="M274.014,16H237.986L16,445.174V496H496V445.174ZM464,464H48V452.959L256,50.826,464,452.959Z"
                ></path>
            </svg>
            <p className="mb-0"><b>{msg}</b></p>
        </div>
    </div>
    );
};

PopUpMsg.propTypes = {
  msg: PropTypes.string,
  onClose: PropTypes.func
};

export {PopUpMsg};
