import React, { useState, useEffect } from 'react';
import TokenABI from "../config/abi/standardTokenABI.json"
import { ethers } from "ethers";

//spender = Contract
async function Allowance( tokenAddress, spender, provider, signer ) {
  //const web3 = new Web3( Web3.givenProvider);
  //console.log('allowance test:', tokenAddress, spender, provider, signer)
  var checkAllowance = 0
  try {
    let tokenContract = new ethers.Contract(tokenAddress, TokenABI.abi,  provider)
    checkAllowance = await tokenContract.allowance(signer, spender)

  } catch (e) {
      checkAllowance = 0
      //console.log('check allowance error:',e)
  }

  return parseInt( Number(checkAllowance ))


}
export {Allowance};

 //tokenAddress, spender, provider, signer
