import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import LotteryABI from "../../config/abi/NFTLotteryABI.json"; // Adjust the path if needed

// Function to fetch winInterval and lastDrawTime from the smart contract
async function LotteryDrawInfo(lotteryAddress, provider, signer) {
    let drawInfo = {
        drawInterval: 0,
        lastDrawTime: 0
    };

    try {
        // Initialize the Lottery Contract with the provided address, ABI, provider, and signer
        let LotteryContract = new ethers.Contract(lotteryAddress, LotteryABI.abi, provider).connect(signer);

        // Fetch the `lastWinInterval` and `lastDrawTime` from the smart contract
        let winInterval = await LotteryContract.lotteryInterval();
        let lastDrawTime = await LotteryContract.lastDrawTime();

        // Store the results in the lotteryInfo object
        drawInfo.drawInterval = Number(winInterval);
        drawInfo.lastDrawTime = Number(lastDrawTime);
    } catch (error) {
        // Handle any errors (e.g., interaction or network issues)
        console.error("Error fetching lottery draw information:", error);
    }

    // Log the fetched info for debugging purposes
    console.log("Lottery draw Info:", drawInfo);

    // Return the fetched information
    return drawInfo;
}

export { LotteryDrawInfo };
