import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Approve } from '../modules/actions/approve';
import { useGlobalState } from './context/GlobalStateProvider';
import { Allowance } from '../modules/allowance';
import contracts from '../config/contracts/contracts.json';
import tokens from '../config/contracts/tokens.json';
import RPCs from '../config/RPCs.json';
import { useError } from './context/ErrorProvider';

const ApproveBtn = ({ quantity, chain, contractType, tokenType, isApproved, setIsApproved, user, children }) => {
  const { setChainData, chainData, isConnected, walletInfo } = useGlobalState();
  const [disabled, setDisabled] = useState(true);
  const [allowanceData, setAllowanceData] = useState();
  const [approvalData, setApprovalData] = useState();
  const {showPopUp} = useError();

  const chainId = useMemo(() => {
    return Object.entries(RPCs).find((i) => i[1].chain === chain)?.[0];
  }, [chain]);

  const tokenAddress = useMemo(() => {
    return tokens[tokenType]?.[chainId];
  }, [tokenType, chainId]);

  const contractAddress = useMemo(() => {
    return contracts[contractType]?.[chainId];
  }, [contractType, chainId]);

  useEffect(() => {
    const checkApproved = () => {
      if (!allowanceData) { return false; }

      if (allowanceData['allowance'] >= quantity*1e9) {
        setDisabled(true);
        setIsApproved(true);
      } else {
        setIsApproved(false);
        setDisabled(false);
      }
    };

    checkApproved();
  }, [quantity, allowanceData, setIsApproved]);

async function fetchData(){

  let allowanceInfo = await Allowance(tokenAddress, contractAddress, walletInfo.provider, walletInfo.signer);
  setAllowanceData({ 'allowance': allowanceInfo });
  return
}

var i = 0
  useEffect(() => {
    const interval = setInterval(() => {
      i = i + 1

      //fetchData()
      const fetcherData = async () => {
        let allowanceInfo = await Allowance(tokenAddress, contractAddress, walletInfo.provider, walletInfo.signer);
        setAllowanceData({ 'allowance': allowanceInfo });
        return allowanceInfo
      }
      fetcherData()
      console.log('interval started',i, allowanceData['allowance'], quantity*1e9)

      if (allowanceData['allowance'] >= quantity*1e9) {
        setDisabled(true);
        setIsApproved(true);
      } else {
        setIsApproved(false);
        setDisabled(false);
      }

      //FetchAllowance()
    }, 5000);


    return () => clearInterval(interval);
  }, [allowanceData]);

  useEffect(() => {
    if (!isConnected || !user || !quantity) { return; }

    const getData = async () => {
      const tmpObj = {
        chainId,
        tokenAddress,
        contractAddress
      };

      let allowanceInfo = await Allowance(tmpObj.tokenAddress, tmpObj.contractAddress, walletInfo.provider, walletInfo.signer);
      //console.log('allowanceInfo:', allowanceInfo);
      setApprovalData(tmpObj);
      setAllowanceData({ 'allowance': allowanceInfo });
    };

    getData();
  }, [isConnected, user, quantity, chainId, tokenAddress, contractAddress, walletInfo]);

  const approve = useCallback(async () => {

    if( parseInt(chainId) !== parseInt(window.ethereum.chainId, 16)){
      showPopUp("Error Wrong Chain. Please switch to: " + chain, "error")
      console.log('chainid',parseInt(window.ethereum.chainId, 16), chainId )
    } else {
      const input = quantity * 10 ** 9;
      var approved = await Approve(approvalData.tokenAddress, approvalData.contractAddress, input, walletInfo.provider, walletInfo.signer);
      if(approved[0]){
        showPopUp("Successful Approval: "+ approved[1], "success")
      }else{
        showPopUp("Failed to Approve: "+ approved[1], "error")
      }
    }
}, [approvalData, quantity, walletInfo]);

  return (
    <>
      {isApproved ? children : <button disabled={disabled} onClick={approve} className={isApproved ? 'disabled button' : 'button'}>Approve</button>}
    </>
  );
};

export { ApproveBtn };
