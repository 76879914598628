import React, { useEffect, useState } from 'react';
import { ErrorProvider, useError } from '../context/ErrorProvider'; // Import the ErrorProvider and useError
import { PopUpMsg, } from './PopUpMsg';

// A functional component to handle error pop-ups
const ErrorPopupHandler = () => {
    const { error, errorType, closePopup } = useError(); // Destructure closePopup directly
    return (
      <>
        {error !== "" && <PopUpMsg msg={error} errorType={errorType} onClose={closePopup} />}
      </>
    );
  };
  
export {ErrorPopupHandler}