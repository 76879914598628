import React, { useState, useEffect } from 'react';
import { useError } from '../context/ErrorProvider'; // Import the useError hook

const CustomInput = ({ input, setInput, maxVal }) => {
  const { showPopUp } = useError(); // Use the error context

  const handleInputChange = (e) => {
    e.preventDefault();
    const newValue = parseFloat(e.target.value) || 0;

    if (newValue > maxVal) {
      showPopUp(`Value cannot exceed ${maxVal}`, "inputError"); // Show error if value exceeds maxVal
    } else {
      setInput(newValue);
    }
  };

  const handleSliderChange = (e) => {
    const sliderValue = parseInt(e.target.value);
    const newValue = (maxVal * sliderValue) / 100;
    setInput(newValue);
  };

  // // useEffect to set the initial value of the input to maxVal when the component mounts
  // useEffect(() => {
  //   setInput(maxVal);
  // }, [setInput, maxVal]);

  return (
    <div className='customInput-container'>
      <input
        className="customInput"
        id="CustomInput"
        value={input}
        onChange={handleInputChange}
        max={maxVal}
      />
      <input
        className='error-slider'
        type="range"
        id="slider"
        name="slider"
        min="0"
        max="100"
        step="10"
        value={(input / maxVal) * 100}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export { CustomInput };
