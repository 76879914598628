// ErrorProvider.js
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ErrorContext = createContext(); // Ensure this is exported

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState("");
  const [errorType, setErrorType] = useState("");

  const showPopUp = (errMsg, errorType) => {
    setError(errMsg);
    setErrorType(errorType);

    // Automatically close the error pop-up after a certain duration (e.g., 3 seconds)
    setTimeout(() => {
      closePopup();
    }, 5000);
  };

  const closePopup = () => {
    setError("");
  };

  return (
    <ErrorContext.Provider value={{ error, errorType, showPopUp, closePopup }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  return useContext(ErrorContext);
};
