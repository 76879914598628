import './assets/scss/style.scss';
import { Home } from './pages/home';
import {MemeGallery} from './pages/memeGallery'
import { NavBar, Footer, Loading } from './components'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import { Page404 } from './pages/Page404';
import { Dapp } from './pages/dapp/Dapp';
import { GlobalStateProvider } from './components/context/GlobalStateProvider';
import { ErrorProvider } from './components/context/ErrorProvider';
import { ErrorPopupHandler } from './components/error/ErrorPopupHandler';

function App() {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', minWidth: '100vw' }}>
    <Router>
      <GlobalStateProvider>
      <NavBar/>
      <body class="is-boxed has-animations boxed-container">
      <Loading/> 
      <ErrorProvider>
      <ErrorPopupHandler />
      <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/memes/*" element={<MemeGallery />} />
            <Route path='/dapp/*' element={<Dapp />}/>
            <Route path="*" element={<Page404 />} />
        </Routes>
      </ErrorProvider>
        <Footer />
        </body>
        </GlobalStateProvider>
    </Router>
    </div>
  )
}

export default App;
