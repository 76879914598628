import React, { useState, useEffect, useRef } from "react";
import blueBlack from "../../assets/images/background/blue-black.jpg";
import { PlusMinusInput } from "../../components/inputs";
import "./Lottery.css";
import { useGlobalState } from "../../components/context/GlobalStateProvider";
import { CurrentGame } from "../../modules/lottery/currentGame";
import { LotteryDrawInfo } from "../../modules/lottery/getDrawInfo";
import { DrawLottery } from "../../modules/lottery/actions/drawWinner";
import { WinAmount } from "../../modules/lottery/winAmount";
import { PaginateWinners } from "../../modules/lottery/paginateWinners";
import { PaginateWinnersExtra } from "../../modules/lottery/paginateWinnersExtra";
import tokensAddrs from "../../config/contracts/tokens.json";
import contractAddrs from "../../config/contracts/contracts.json";
import { TokenBalance } from "../../modules/tokenBalance";
import { NftETHFees } from "../../modules/nfts/nftEthFees";
import { MintWithEth } from "../../modules/nfts/mintWithEth";
import { NftContractInfo } from "../../modules/nfts/nftContractInfo";
import { useError } from "../../components/context/ErrorProvider";
import { TicketCardController } from "../../components/lottery/TicketCardController";
import { holdersNftsCollection } from "../../modules/nfts/holdersNftsCollection";
import { Pagination } from "../../components/Pagination";
import nftTickets from "../../assets/images/banners/nftTickets.png";
import lotteryIcon from "../../assets/images/icons/lottery2.png";
import lottoWinner from "../../assets/images/icons/trophy-png-25059.png";

const LotteryPage = () => {
  const { walletInfo, isConnected } = useGlobalState();
  const [nextDraw, setNextDraw] = useState(0);
  const [nextUnix, setNextUnix] = useState(0);
  const [countDown, setCountDown] = useState();
  const [lastDraw, setLastDraw] = useState(0);
  const [prizeValue, setPrizeValue] = useState(0);
  const [prizeInterval, setPrizeInterval] = useState(0); // Prize every x minutes
  const [lotteryTVL, setLotteryTVL] = useState(0); // Total Value Locked
  const [nftInfos, setNftInfos] = useState(0); // Mint progress
  const [ticketsToMint, setTicketsToMint] = useState(1); // Number of tickets to mint
  const [ethFee, setEthFee] = useState(0.05); // ETH to lock per ticket
  const [tokenAddy, setTokenAddy] = useState();
  const [currentGameId, setCurrentGameId] = useState();
  const { showPopUp, closePopup } = useError();
  const [toPlay, setToPlay] = useState();
  const [pagination, setPagination] = useState({
    winners: { page: 1, resultsPerPage: 8 },
    holders: { page: 1, resultsPerPage: 8, unique_token: "" },
  });
  const [pageWinAmount, setPageWinAmount] = useState([]);
  const [winnerAddress, setWinnerAddress] = useState([]);
  const [prizeAddresses, setPrizeAddresses] = useState([]);
  const [drawTimes, setDrawTimes] = useState([]);
  const [winnerTokenIds, setWinnerTokenIds] = useState([]);
  const [holders, setHolders] = useState([]);
  const [filteredHolders, setFilteredHolders] = useState([]);
  const [view, setView] = useState("holders");

  useEffect(() => {
    const { page: holderPage, resultsPerPage: holderResultsPerPage } = pagination.holders;
    const HolderStartIndex = (holderPage - 1) * holderResultsPerPage;
    const HolderEndIndex = HolderStartIndex + holderResultsPerPage;
    setFilteredHolders(holders.slice(HolderStartIndex, HolderEndIndex));
  }, [holders, pagination.holders.page, pagination.winners.page]);

  const previousTokenRef = useRef(null);

  useEffect(() => {
    const currentToken = pagination?.holders?.unique_token;
    if (currentToken !== previousTokenRef.current) {
      paginateHolders(); // Call the function
      previousTokenRef.current = currentToken; // Update the ref with the current token
    }
  }, [filteredHolders, pagination.holders.unique_token]);

  useEffect(() => {
    //WALLET INFO HERE!
    if (isConnected) {
      var tokenAddress = tokensAddrs.Friendship[369];
      //tokenAddress = "0xc674B7Bed1D086584c8776C1B83013009cC294e7";
      //tokenAddress = "0x900a033403556DB01b830DdDE80EbF69403f49dC";
      setTokenAddy(tokenAddress);
      const fetchData = async () => {
        try {
          console.log("tokenAddy", tokenAddress);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      //getConditionalInfo()
      getLotteryInfo();
      lotteryBalance();
      ethFees();
      paginateDetails();
      getNFTcontractinfo();
    }
  }, [isConnected]);

  useEffect(() => {

    const timer = setInterval(() => {
      getLotteryInfo();
      //paginateDetails();
      //lotteryBalance();
      const now = Date.now();
      // Split the date and time parts
      if(!nextDraw){return}

      //const [datePart, timePart] = nextDraw.split(",");
      //const nextDrawParts = nextDraw[0]
      //console.log('nextDrawParts',nextDrawParts[0])
      // Split the date into day, month, and year
      //const [day, month, year] = datePart.split("/");


      // Split the time into hours, minutes, and seconds
      //const [hours, minutes, seconds] = timePart.split(":");

      // Create a new Date object with the parsed values
      //const nextDrawTime = new Date(year, month - 1, day, hours, minutes, seconds);
      const difference = nextUnix - now;
      //console.log('countDown',countDown,'nextDrawUnix:',nextUnix , now)
      if (difference <= 0) {
        setCountDown(0);
       } else {
       const seconds = Math.floor(difference / 1000);
         setCountDown(seconds);
         console.log('countDown',countDown)
       }
    }, 5000);
    const slowUpdate = setInterval(()=>{
      lotteryBalance();
      //paginateDetails();
    },8000);

    return () => {
      clearInterval(timer);
      clearInterval(slowUpdate);
    }
  }, [nextDraw]);

  async function getLotteryInfo() {
    //console.log("draw", contractAddrs.lottery[369]);
    let drawInfo = await LotteryDrawInfo(
      contractAddrs.lottery[369],
      walletInfo.provider,
      walletInfo.signer
    );
    let gameId = await CurrentGame(
      contractAddrs.lottery[369],
      walletInfo.provider,
      walletInfo.signer
    );
    let winInfo = await WinAmount(
      contractAddrs.lottery[369],
      walletInfo.provider,
      walletInfo.signer
    );

    setCurrentGameId(gameId);
    let ldrawtime = new Date(drawInfo["lastDrawTime"] * 1000).toLocaleString();
    let next = drawInfo["lastDrawTime"] + drawInfo["drawInterval"];
    let ndrawtime = new Date(next * 1000).toLocaleString();
    let pInterval = (drawInfo["drawInterval"] / 3600).toFixed(0);
    //console.log("draw time", ldrawtime, ndrawtime);

    let pvalue = winInfo[0] / 10 ** winInfo[3];
    setPrizeInterval(pInterval);
    setNextDraw(ndrawtime);
    setNextUnix(next*1000);
    setLastDraw(ldrawtime);
    setPrizeValue(pvalue);
  }

  async function lotteryBalance() {
    let tokenAddress = "0x900a033403556DB01b830DdDE80EbF69403f49dC";
    let lotteryBal = await TokenBalance(
      tokenAddress,
      contractAddrs.lottery[369],
      walletInfo.provider,
      "blank"
    );
    //let lotteryBal = await TokenBalance( tokenAddy, contractAddrs.lottery[369], walletInfo.provider,'blank')
    //console.log("balance", tokenAddress, lotteryBal); 0x57c9eE466685BA0929a36756e0Be39BC0066E506
    setLotteryTVL(lotteryBal);
  }

  async function drawWinner() {
    let result = await DrawLottery(
      contractAddrs.lottery[369],
      walletInfo.provider,
      walletInfo.signer
    );
    if (result[0]) {
      showPopUp("Transaction Success: " + result[1], "success");
      setToPlay(1);
    } else {
      showPopUp("Error: " + result[1], "error");
    }
  }

  async function ethFees() {
    //timeout(2000)
    var fees = await NftETHFees(
      contractAddrs.NFTs[369],
      walletInfo.provider,
      walletInfo.signer
    );
    //console.log("fees", fees[0]);
    setEthFee(Number(fees[0]) / 10 ** 18);
    //var rfeeMain = Math.round(fees[4]*100)*fees[5]
    //var userRefMain =  Math.round((fees[4]*100)*(1-fees[5]))
    //setReferFee( rfeeMain )
    //setUserReferFee(userRefMain)
  }

  async function DoEthMint() {
    if (
      !ethFee ||
      !isConnected ||
      !walletInfo?.signer?.address ||
      !walletInfo?.signer?.provider
    ) {
      console.log("DoEthMint: data missing");
      return;
    }
    var amount = ticketsToMint * parseInt(ethFee); //ethFees
    //ticketsToMint * ethFee).toFixed(2)
    //referrer should come from the form where the referrer wallet is set
    var referrer;
    console.log("MINT input", ticketsToMint, referrer, amount);
    var result = await MintWithEth(
      contractAddrs.NFTs[369],
      ticketsToMint,
      referrer,
      amount,
      walletInfo.provider,
      walletInfo.signer
    ); //added result on async
    if (result[0]) {
      showPopUp("Transaction Success: " + result[1], "success");
      setToPlay(1);
    } else {
      showPopUp("Error: " + result[1], "error");
    }
  }

  useEffect(()=>{
    paginateDetails()
  },[currentGameId, pagination?.winners?.page])

  function fixPage( pNum ){
    let ans = 1+Math.floor(currentGameId /pagination.winners.resultsPerPage) -pNum

    return pNum
  }

   fixPage( pagination.winners.page )
  //pagination function starts at 1
  async function paginateDetails() {
    console.log(
      "paginated winners params",
      pagination.winners.page,
      pagination.winners.resultsPerPage
    );

    var pageWin = await PaginateWinners(
      contractAddrs.lottery[369],
      fixPage(pagination.winners.page),
      pagination.winners.resultsPerPage,
      walletInfo.provider
    );
    var pageWinXtra = await PaginateWinnersExtra(
      contractAddrs.lottery[369],
      fixPage(pagination.winners.page),
      pagination.winners.resultsPerPage,
      walletInfo.provider
    );

    if (pageWin && pageWinXtra) {
      setPageWinAmount(pageWin["prizeAmounts"]);
      setWinnerAddress(pageWin["winnerAddresses"]);
      setPrizeAddresses(pageWinXtra["prizeAddresses"]);
      setDrawTimes(pageWinXtra["drawTimes"]);
      setWinnerTokenIds(pageWinXtra["tokenIDs"]);
    }
  }

//console.log('forward:', winnerTokenIds ,'reverse',winnerTokenIds.reverse())

  async function paginateHolders() {
    if (pagination.holders.unique_token === null) {
      console.log("No more pages to fetch.");
      return;
    }

    try {
      const resp = await holdersNftsCollection(
        pagination.holders.resultsPerPage,
        pagination.holders.unique_token
      );
      setPagination((prev) => {
        if (prev.holders.unique_token !== resp[1]) {
          return {
            ...prev,
            holders: {
              ...prev.holders,
              unique_token: resp[1],
            },
          };
        }
        return prev;
      });

      // Update holders list without overwriting the state
      setHolders((prevHolders) => {
        const tmpList = [...prevHolders];
        resp[0].forEach((item) => {
          if (!tmpList.some((holder) => holder.id === item.id)) {
            tmpList.push(item); // Add only unique items
          }
        });
        return tmpList;
      });
    } catch (error) {
      console.error("Error paginating holders:", error);
    }
  }

  async function getNFTcontractinfo() {
    let nInfos = await NftContractInfo(
      contractAddrs.NFTs[369],
      walletInfo.provider,
      walletInfo.signer
    );
    console.log("ninfos", nInfos);
    setNftInfos([Number(nInfos[0]), Number(nInfos[1])]);
  }

  async function doRefresh(){
    getLotteryInfo()
    lotteryBalance()
    getNFTcontractinfo()
    paginateDetails()
    ethFees()
  }

  function getToken( tknAddy ){
    let tkn = "FRIENDSHIP"
    //console.log('tkn',tkn )
    if ( tknAddy == "0x900a033403556DB01b830DdDE80EbF69403f49dC"){
      tkn = "TESTTKN"
    }
    if ( tknAddy == "0xc674B7Bed1D086584c8776C1B83013009cC294e7"){
      tkn = "DEMOTKN"
    }
    return tkn

  }
  const contract_url = "https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/address/"+contractAddrs.lottery[369]
  const formatTime = (seconds) => {
    const weeks = Math.floor(seconds / (7 * 24 * 3600)); // Calculate weeks
    const days = Math.floor((seconds % (7 * 24 * 3600)) / (24 * 3600)); // Calculate days
    const hours = Math.floor((seconds % (24 * 3600)) / 3600); // Calculate hours
    const minutes = Math.floor((seconds % 3600) / 60); // Calculate minutes
    const secs = seconds % 60; // Calculate remaining seconds

    let result = [];

    if (weeks > 0) result.push(`${weeks} weeks`);
    if (days > 0 || weeks > 0) result.push(`${days} days`); // Only show days if weeks > 0 or days > 0
    if (hours > 0 || days > 0 || weeks > 0) result.push(`${hours} hours`); // Show hours if any larger unit exists or hours > 0
    if (minutes > 0 || hours > 0 || days > 0 || weeks > 0) result.push(`${minutes} minutes`); // Show minutes if any larger unit exists or minutes > 0
    result.push(`${secs < 10 ? "0" : ""}${secs} seconds`); // Always show seconds, pad with 0 if less than 10

    return result.join(", ");
  };

  return (
    <section className="newsletter section">
      <div className="demoDapp-container">
        <div>
          <div className="panel panel-with-banner panel-with-banner-lottery">
            <div className="lottery-banner-img general-banner-img" />
          </div>

          <div className="info-panel">
            <div className="pool-info panel edging">
              <div className="info-panel-inner">
                <h3 className="blueHead">
                  <b>PRIZE: {prizeValue}  FRIENDSHIP</b>
                </h3>
                <br/>
                <img className="trophy-icon" src={lottoWinner} />

                <b>Draw Round: {currentGameId}</b>
                <br />
                <b>Prize Draw Every: {prizeInterval} Hours</b>
                <br />
                <b>Total NFT: {nftInfos[1]}/{nftInfos[0]}</b>
                <br />
                <b>Lottery TVL: {lotteryTVL} FRIENDSHIP</b>
<br/><br/>
                <button className="button blueBttn" onClick={doRefresh}>
                  Refresh ⟳
                </button>
              </div>
            </div>

            <div className="next-draw-panel panel edging">

                <b>
                  {countDown !== undefined ? countDown === 0 ? (
                    <>
                      <div className="draw-now-panel"> Next Draw Available Now!</div>
                    </>
                  ) : (
                    <div className="draw-now-panel">
                    {formatTime(countDown)}
                    </div>

                  ) : <div className="draw-now-panel">Loading...</div>
                  }
                </b>
<br/>

              <b>
                Next Draw: <br /> {nextDraw} <br />
                {/* <img className="lotto-icon" src={lotteryIcon} /> walletAddress*/}
              </b><br/>
              <b>
                Previous Draw: <br /> {lastDraw} <br />
              </b><br/>
              <b><a href={contract_url}>{contractAddrs.lottery[369]}</a> </b><br/>
              <button className="button 6goldBttn" onClick={drawWinner}>Manual Draw</button>

            </div>

            <div className="participate-panel panel edging">
              <div className="info-panel-inner">
                <h3 className="blueHead">
                  <b >{ethFee} PLS per NFT</b>
                </h3>
                <br/>
                <img className="ticket-logo" src={nftTickets} />
                <br/><b>
                  By minting {ticketsToMint} ticket(s) you will spend{" "}
                  {(ticketsToMint * ethFee).toFixed(0)} PLS
                </b>
                <PlusMinusInput
                  value={ticketsToMint}
                  setValue={setTicketsToMint}
                />
                <button className="button blueBttn" onClick={DoEthMint}>
                  Mint {ticketsToMint} Ticket(s)
                </button>


              </div>
            </div>
          </div>


          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => setView("holders")}
              style={view === "holders" ? { backgroundColor: "grey" } : {}}
              className="button"
            >
              Holders
            </button>
            <button
              onClick={() => setView("winners")}
              style={view === "winners" ? { backgroundColor: "grey" } : {}}
              className="button"
            >
              Winners
            </button>
          </div>

          {view === "winners" ? (
            <Pagination
              currentPage={pagination?.winners?.page}
              totalPages={Math.ceil(
                currentGameId / pagination?.winners?.resultsPerPage
              )}
              isCapped={true}
              onPageChange={(newPage) =>
                setPagination((prevState) => ({
                  ...prevState,
                  winners: {
                    ...prevState.winners,
                    page: newPage, // Set the new page here
                  },
                }))
              }
            >
              <div
                className="tickets" //Generates the winners grid
              >
                {winnerAddress?.map((address, ind) => (

                  <TicketCardController
                    key={ind}
                    ticket={{
                      url: "https://mdxf4gqxdcfu7orq7y2qk6eand2diki3l4ce4sotmieedednifiq.arweave.net/YO5eGhcYi0-6MP41BXiAaPQ0KRtfBE5J02IIQZBtQVE/",
                      wallet: winnerAddress?.[ind],
                      prizeAddress: getToken(prizeAddresses?.[ind]),
                      winnerToken: winnerTokenIds?.[ind],
                      amount:pageWinAmount?.[ind],
                      drawTime: new Date(
                        Number(drawTimes?.[ind]) * 1000
                      ).toLocaleString(),
                    }}
                    isWinner={true}
                  />
                ))}
              </div>
            </Pagination>
          ) : (
            view === "holders" && (
              <Pagination
                currentPage={pagination?.holders?.page}
                totalPages={Math.ceil(
                  holders?.length / pagination?.holders?.resultsPerPage
                )}
                onPageChange={(newPage) =>
                  setPagination((prevState) => ({
                    ...prevState,
                    holders: {
                      ...prevState.holders,
                      page: newPage, // Set the new page here
                    },
                  }))
                }
              >
                <div
                  className="tickets" //Generates the holders grid
                >
                  {filteredHolders?.map((ticket) => (
                    <TicketCardController
                      key={ticket.id}
                      ticket={ticket}
                      isWinner={false}
                    />
                  ))}
                </div>
              </Pagination>
            )
          )}
        </div>
        <img src={blueBlack} />
      </div>
    </section>
  );
};

export { LotteryPage };
